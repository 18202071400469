<template>
    <div>
        <v-dialog v-model="showDialogExport" fullscreen hide-overlay persistent transition="dialog-bottom-transition">
            <v-card>
                <v-toolbar dark color="oee-theme">
                    <v-btn icon dark @click="closePopup">
                        <v-icon>fa-times-circle</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-toolbar-title> Xuất kho VN ngày {{currentTime | display_date('DD-MM-YYYY')}}</v-toolbar-title>
                </v-toolbar>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red darken-1 white--text"  class="mt-8 mr-2" @click="printDetail">In phiếu</v-btn>
                </v-card-actions>
                <v-card-text>
                    <v-container grid-list-xl fluid>
                        <v-layout row wrap>
                            <v-flex lg3 md3 sm12 pa-0>
                                <v-autocomplete label="Khách hàng" v-model="selectedCustomerID" :items="customers" item-text="CustomerCode" item-value="ID" @change="onSelectCustomer"></v-autocomplete>
                            </v-flex>
                            <v-flex lg4 md4 sm12 pa-0 pl-2>
                                <v-autocomplete label="Địa chỉ nhận" v-model="selectedAddressID" :items="addressBooks" item-text="Receiver" item-value="ID" @change="onSelectAddress"></v-autocomplete>
                            </v-flex>
                        </v-layout>
                        <v-layout row wrap>
                            <v-flex lg3 md3 sm12 pa-0>
                                <v-text-field label="Số điện thoại" v-model="selectedAddress.Phone" readonly></v-text-field>
                            </v-flex>
                            <v-flex lg9 md9 sm12 pa-0 pl-2>
                                <v-text-field label="Địa chỉ" v-model="selectedAddress.Address" readonly></v-text-field>
                            </v-flex>
                        </v-layout>
                        <v-layout row wrap>
                            <v-flex lg12 md12 sm12 pa-0>
                                <v-text-field label="Đơn vị vận chuyển" v-model="shipper" autocomplete="true"></v-text-field>
                            </v-flex>
                        </v-layout>
                        <v-layout row wrap>
                            <v-flex lg12 md12 sm12 xs12 pa-0>
                                <v-text-field :disabled="selectedCustomerID == 0 || selectedAddressID == 0 || shipper == ''" label="Nhập mã phiếu xuất" v-model="exportCode" class="input-group--focused" @keyup.enter="onExportCode"></v-text-field>
                            </v-flex>
                            <v-flex lg12 md12 sm12 xs12 pa-0>
                                <v-data-table :headers="headers" :items="items" hide-default-footer disable-pagination :must-sort="true">
                                <template v-slot:item="{ item, index }">
                                    <tr>
                                        <td>{{ index + 1 }}</td>
                                        <td>{{ item.ID }}</td>
                                        <td class="text-end">{{ item.NumberOfPack | currency }}</td>
                                        <td class="text-end">{{ item.Weight | currency('', 2) }}</td>
                                        <td class="text-end">{{ item.Volume | currency('', 3) }}</td>
                                        <td>{{ item.NoteForCustomer }}</td>
                                        <td class="text-center">
                                            <v-icon small @click="onRevert(item.ID, index)">fa-undo</v-icon>
                                        </td>
                                    </tr>
                                </template>
                                </v-data-table>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import _ from "lodash";
import configs from "@/configs";
import { mapState } from "vuex";
import { getCurrentTime, sendErrorNotice, playSound } from "@/commons/utils";
import { print_export_receipt_list } from '@/commons/hpod';

export default {
    data() {
        return {
            viewName: "xuat-kho-phieu-xuat-vn",
            showDialogExport: true,
            selectedCustomerID: 0,
            selectedCustomer: {},
            selectedAddressID: 0,
            selectedAddress: {},
            exportCode: "",
            shipper: "",
            items: [],
            currentTime: getCurrentTime(),
            dialogImport: false
        }
    },
    computed: {
        ...mapState({
            customers: state => state.customer.master.data,
            addressBooks: state => state.addressBook.all.data,
        }),
        headers() { 
            return [
                {
                    text: "STT",
                    value: "",
                    dataType: configs.DATA_TYPE["Number"]
                },
                {
                    text: "Mã phiếu",
                    value: "ID",
                    filterable: true,
                    quickSearch: true,
                    dataType: configs.DATA_TYPE["Number"]
                },
                {
                    text: "Số kiện",
                    value: "NumberOfPack",
                    filterable: true,
                    align: "end",
                    dataType: configs.DATA_TYPE["Number"]
                },
                {
                    text: "Cân nặng",
                    value: "Weight",
                    filterable: true,
                    align: "end",
                    dataType: configs.DATA_TYPE["Number"]
                },
                {
                    text: "Kích thước",
                    value: "Volume",
                    filterable: true,
                    align: "end",
                    dataType: configs.DATA_TYPE["Number"]
                },
                {
                    text: "Ghi chú",
                    value: "NoteForCustomer",
                    align: "center",
                    filterable: true,
                    quickSearch: true,
                },
                {
                    text: "Thao tác",
                    value: "",
                    align: "center",
                    sortable: false,
                    filterable: false
                }
            ];
        }
    },
    methods: {
        openExportDialog() {
            this.showDialogExport = true;
        },
        closePopup() {
            window.getApp.$emit("APP_NAVIGATE", "Dashboard");
        },
        getCustomerList() {
            this.$store.dispatch("customer/getCustomerList", {
                is_master: 1,
                fields: ["ID", "Username", "CustomerCode"]
            });
        },
        onSelectCustomer() {
            this.$store.dispatch("addressBook/getList", {
                filter: [{
                    column: "UID",
                    value: this.selectedCustomerID,
                    condition: "equal"
                }]
            });
            this.selectedCustomer = _.find(this.customers, {ID: this.selectedCustomerID});
        },
        onSelectAddress() {
            this.selectedAddress = _.find(this.addressBooks, {ID: this.selectedAddressID});
        },
        onExportCode() {
            const existed_item = _.find(this.items, (item) => {return item.ID == Number(this.exportCode)});
            if(existed_item) {
                playSound('/vtb-error.mp3');
                sendErrorNotice('Mã phiếu đã được xuất kho.');
                return;
            }
            this.$store.dispatch("exportReceipt/getDetail", Number(this.exportCode)).then((ret) => {
                if(ret) {
                    if(ret.UID != this.selectedCustomerID) {
                        playSound('/vtb-error.mp3');
                        sendErrorNotice('Phiếu xuất không phải của khách hàng đã chọn.');
                        return;
                    }
                    if(ret.Status == 1) {
                        playSound('/vtb-error.mp3');
                        sendErrorNotice('Phiếu đã được xuất kho.');
                        return;
                    }
                    playSound('/vtb-success.mp3');
                    this.$store.dispatch("exportReceipt/setDetail", {
                        data: {
                            ID: ret.ID,
                            Status: 1,
                            Receiver: this.selectedAddress.Receiver,
                            Phone: this.selectedAddress.Phone,
                            Address: this.selectedAddress.Address,
                            Shipper: this.shipper
                        },
                        id: ret.ID
                    });
                    this.items.push({'ID': ret.ID, 'Weight': ret.Weight, 'Volume': ret.Volume, 'NumberOfPack': ret.NumberOfPack, 'NoteForCustomer': ret.NoteForCustomer});
                    this.exportCode = '';
                }
                else { 
                    playSound('/vtb-error.mp3');
                    sendErrorNotice('Có lỗi kết nối tới server.');
                    return;
                }
            })
        },
        onRevert(id, index) {
            this.$store.dispatch("exportReceipt/setDetail", {
                'data': {
                    'ID': id,
                    'Status': 0,
                    'Receiver': '',
                    'Phone': '',
                    'Address': '',
                    'Shipper': ''
            },
                'id': id
            });
            this.items.splice(index, 1);
        },
        printDetail() {
            if(!this.selectedCustomerID) {
                playSound('/vtb-error.mp3');
                sendErrorNotice('Hãy chọn khách hàng.');
                return;
            }
            if(!this.items || this.items.length == 0) {
                playSound('/vtb-error.mp3');
                sendErrorNotice('Danh sách phiếu xuất trống.');
                return;
            }
            if(this.shipper == '') {
                playSound('/vtb-error.mp3');
                sendErrorNotice('Hãy nhập đơn vị vận chuyển.');
                return;
            }
            const frame_printing = print_export_receipt_list({Username: this.selectedCustomer ? this.selectedCustomer.CustomerCode : '', Address: this.selectedAddress.Address, Phone: this.selectedAddress.Phone, Receiver: this.selectedAddress.Receiver, Voter: this.$store.state.authentication.user.fullname, Shipper: this.shipper}, this.items, true);
            var doc = document.getElementById('print_frame').contentWindow.document;
            doc.open();
            doc.write(frame_printing);
            doc.close();
            setTimeout(function(){ window.frames["print_frame"].window.print(); }, 1000);
        }
    },
    mounted() {
        this.getCustomerList();
        this.$root.$emit("REQUEST_ROOT_OPTION", {});
        this.$root.$on("REQUEST_CREATE_DATA", this.openExportDialog);
    },
    beforeDestroy() {
        this.$root.$off("REQUEST_CREATE_DATA", this.openExportDialog);
    },
}
</script>